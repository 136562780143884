<template>
  <tr>
    <td>
      <label v-if="campaign.campaignName" class="label">
        {{ campaign.campaignName }}
        <span class="tag is-hidden-mobile" :class="[tagClass]">
          {{ tagName }}
        </span>
      </label>
      <label v-else class="label has-text-danger">
        [Draft]
      </label>
    </td>
    <td v-if="campaign.submittedAt">
      <label class="label">Submitted {{ formatDate(campaign.submittedAt) }}</label>
      <p class="help">created {{ formatDate(campaign.created) }}</p>
    </td>
    <td v-else>
      <label class="label" v-if="new Date().getTime() > campaign.expiryDate.toDate().getTime()">Expired {{ formatDate(campaign.expiryDate) }}</label>
      <label v-else class="label">Expires {{ formatDate(campaign.expiryDate) }}</label>
      <p class="help">created {{ formatDate(campaign.created) }}</p>
    </td>
    <td class="is-hidden-mobile">
      <span v-if="campaign.mode === 'invite'">{{ acceptedInvites.length }}/</span>{{ invites.length }} recipients
    </td>
    <td class="has-text-right">
      <div v-if="!campaign.launchedAt" class="buttons is-justify-content-flex-end">
        <router-link
          :to="{ name: 'CreateCampaign', params: { campaignId: campaign.id }}"
          class="button is-info py-0 m-0"
          style="height: 1.5rem;"
        >
          Edit
        </router-link>
        <button
          class="button is-danger py-0 m-0"
          style="height: 1.5rem;"
          @click="doDeleteCampaign()"
        >
          Delete
        </button>
      </div>
      
      <template v-else>
        <router-link
          :to="{ name: 'ViewCampaign', params: { campaignId: campaign.id }}"
          class="button is-info is-hidden-mobile"
        >
          <span>View Campaign</span>
          <Icon icon="chevron-right" />
        </router-link>
        <router-link
          :to="{ name: 'ViewCampaign', params: { campaignId: campaign.id }}"
          class="has-text-black is-hidden-tablet"
        >
          <Icon icon="chevron-right" />
        </router-link>
      </template>
    </td>
  </tr>
</template>

<script>
import { inject, computed } from 'vue'
import useDocument from '@/composables/useDocument'
import Modal from '@/components/Modals/Modal.vue'
import InviteAcceptedModal from '@/components/Modals/InviteAcceptedModal.vue'
import Icon from '@/components/Icon.vue'
import filters from '@/utils/filters'

export default {
  props: ['campaign', 'invites'],
  components: { Modal, InviteAcceptedModal, Icon },
  setup(props) {
    const isPending = inject('isPending')

    const acceptedInvites = computed(() => {
      return props.invites.filter(invite => invite.acceptedAt)
    })

    const doDeleteCampaign = async () => {
      isPending.value = true
      const { deleteDoc: deleteCampaign } = useDocument('campaigns', props.campaign.id)      
      await deleteCampaign()
      isPending.value = false
    }

    // Formatting 
    const tagClass = computed(() => {
      
      
      if (props.campaign.campaignName == 'test1') {
        console.log ('launchedAt', props.campaign.launchedAt)
        console.log ('campaign', props.campaign)
        console.log ('invite.length', props.invites.length)
      }

      if (props.campaign.mode === 'invite' && props.invites.length != 0) {
        // Live/Ready/Processing Invite
        return 'is-warning'
      } else if (props.invites.length == 0 && !props.campaign.launchedAt) {
        // Drafts
        return props.campaign.mode === 'invite' ? 'is-warning' : 'is-success'
      } else if (props.campaign.mode === 'invite' && props.invites.length == 0)  {
        // Cancelled
        return 'is-danger'
      } else { 
        // Live/Ready/Processing Public
        return 'is-success'
      }
    })

    const tagName = computed (() => {
      if (props.campaign.mode === 'invite' && props.invites.length != 0) {
        return 'invitation'
      } else if (props.invites.length == 0 && !props.campaign.launchedAt) {
        // Drafts
        return props.campaign.mode === 'invite' ? 'invitation' : 'public'
      }else if (props.campaign.mode === 'invite' && props.invites.length == 0 )  {
        return 'cancelled'
      } else {
        return "public"
      }

    })

    


    return {
      acceptedInvites,
      doDeleteCampaign,
      tagClass,
      tagName,
      ...filters,
    }
  }
  
}

  
</script>

<style scoped>
.is-clickable:hover {
  background-color: #fafafa;
}

@media screen and (max-width: 768px) {
  .table td:first-child label {
    max-width: 30vw;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: wrap;
  }

  .table td:nth-child(2) label {
    font-size: 14px;
  }
}
</style>