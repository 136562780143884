<template>
  <button :class="`button is-black ${isPending && 'is-loading'}`" @click="handleSubmit()">
    <Icon icon="plus" class="is-hidden-mobile" />
    <span>New campaign</span>
  </button>

  <Teleport to="body">
    <div v-if="showModal" class="modal is-active is-small">
      <div class="modal-background" @click="showModal = false"></div>
      <div class="modal-card">
        <section class="modal-card-body has-text-centered">
          <img src="@/assets/logo2.svg" width="80" />
          <label class="label mt-3">No Payment Method:</label>
          <p class="mb-5">
            You can not create a new campaign without a payment method, please got to settings and add one.
          </p>
          <router-link :to="{ name: 'Settings' }" class="button is-black">
            Go to settings
          </router-link>
        </section>
      </div>
    </div>
  </Teleport>
</template>

<script>
import { inject, ref, computed } from 'vue'
import { useRouter } from 'vue-router'
import getUser from '@/composables/getUser'
import getCollection from '@/composables/getCollection'
import useCollection from '@/composables/useCollection'
import Modal from '@/components/Icon.vue'
import Icon from '@/components/Icon.vue'
import { timestamp } from '@/firebase/config'

export default {
  components: { Icon },
  setup() {
    const router = useRouter()
    const { user } = getUser()

    const showModal = ref(false)
    const isPending = inject('isPending')
    const paymentMethods = inject('paymentMethods')
    
    const handleSubmit = async () => {
      if (!paymentMethods.value.length) {
        showModal.value = true
        return
      }

      isPending.value = true
      const { addDoc: addCampaign } = useCollection('campaigns')
      const campaign = await addCampaign({
        accountId: user.value.uid,
        accountEmail: user.value.email,
        accountName: user.value.displayName,
        chooseType: 1,
        mode: 'invite',
        products: [],
        clients: [],
        campaignName: '',
        expiryDate: new Date(new Date().setDate(new Date().getDate() + 30)),
        paymentMethod: paymentMethods.value[0].id,
        created: timestamp()
      })
      router.push({ name: 'CreateCampaign', params: { campaignId: campaign.id }})
      isPending.value = false
    }

    return {
      showModal,
      isPending,
      handleSubmit
    }
  }
}
</script>

<style scoped>
a.navbar-item {
  width: 25%;
  text-align: center;
  flex-direction: column;
  justify-content: space-between;
  color: var(--grey);
  padding-inline: 0;
  white-space: nowrap;
}

a.navbar-item:focus, a.navbar-item:focus-within, a.navbar-item:hover, 
a.navbar-item.router-link-active {
  color: var(--primary);
}
</style>